





































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import EditCustomGoalCurrentPlanViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-goal-current-plan-view-model';

@Component({ name: 'EditCustomGoalCurrentPlan' })
export default class EditCustomGoalCurrentPlan extends Vue {
  @Prop({ type: String, required: true })
  customGoalName!: string;

  edit_custom_plan_view_model = Vue.observable(
    new EditCustomGoalCurrentPlanViewModel(this),
  );

  mounted() {
    this.edit_custom_plan_view_model.initialize();
  }
}
