import TYPES from '@/types';
import Vue from 'vue';

// Application
import EditCustomGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-current-plan-service';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditCustomGoalCurrentPlanViewModel {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditCustomGoalPlanCurrentPlanService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-custom-goal-plan.edit_custom_goal';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      value: '',
    },
    {
      label: this.translate('current_amount'),
      value: '',
    },
    {
      label: this.translate('monthly_contribution'),
      value: '',
    },
    {
      label: this.translate('target_date'),
      value: '',
    },
  ]

  initialize = () => {
    this.setInitialValues();
  }

  getCurrentPlantInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  setInitialValues = () => {
    const investor_goal = this.getCurrentPlantInformation();
    this.plan_information[0].value = investor_goal.target_amount;
    this.plan_information[1].value = investor_goal.current_amount;
    this.plan_information[2].value = investor_goal.monthly_required_amount;
    this.plan_information[3].value = investor_goal.final_investment_date;
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }
}
